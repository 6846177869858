<script lang='ts'>
  import { link } from 'svelte-routing'

  import { getIdFromIso, getIsoFromId } from '@/helpers/apiCall'
  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'
  import { toInt } from '@/helpers/mixHelpers'

  import Flag from '@/components/forum/Flag.svelte'
  import Icon from '@/components/icons/Icon.svelte'
  import Breadcrumb from '@/components/ui/Breadcrumb.svelte'
  import EmptyState from '@/components/ui/EmptyState.svelte'
  import FakeButton from '@/components/ui/FakeButton.svelte'
  import LanguageSelect from '@/components/ui/LanguageSelect.svelte'
  import Overlay from '@/components/ui/Overlay.svelte'
  import PageInfo from '@/components/ui/PageInfo.svelte'
  import ProgressBar from '@/components/ui/ProgressBar.svelte'

  import { FetchCatchError, Goal } from '@/definitions/langoid'
  import { goalsStore } from '@/store'

  export let iso = ''

  let goals: Goal[] = [],
    newGoal = {} as Goal,
    createNewGoal = false,
    sent = false,
    language: number = getIdFromIso(iso),
    level: HTMLSelectElement,
    date: HTMLInputElement
  const levels = ['A1', 'A2', 'B1', 'B2']
  const today = new Date().toISOString().split('T')[0]

  function goalProgress (state: number, size: number) {
    const goalProgress = Math.round(state / size * 100)
    return goalProgress < 100 ? goalProgress : 100
  }

  const loadGoals = () => {
    fetchData('main/goalsLoad', { iso }).then((data) => {
      goals = data
    }).catch((error: FetchCatchError) => {
      console.error('goal load', error)
    })
  }

  const deleteGoal = async (id: number) => {
    if (confirm($_('goal.sureToDelete'))) {
      await fetchData('main/goalDelete', { id })
      goals = goals.filter((goal: Goal) => goal.id !== id)
      goalsStore.set(goals)
    }
  }

  const handleSubmit = async () => {
    // e.target.reset()
    fetchData('main/goalNew', { date: date.value, language, level: level.value }).then(data => {
      newGoal = data
      sent = true
      goalsStore.update((goals) => {
        goals = [...goals, newGoal]
        return goals
      })
    })
  }

  const onLanguageChange = (lang: number) => {
    language = lang
    return true
  }

  const statusMessage = (status: Goal['status']) => {
    if (status === 'active') return $_('goal.active')
    if (status === 'success') return $_('goal.success')
    if (status === 'fail') return $_('goal.fail')
    return ''
  }

  $: if (sent) {
    goals.push(newGoal)
  }

  loadGoals()
</script>

<Breadcrumb breads={[{ text: '', url: `/${iso}` },{ text: $_('levelPage.goals')}]}>
  <h2>{$_('goal.title')}</h2>
  <div slot='pageHelperIcons'>
    <PageInfo kebabItem pageName='goals' />
  </div>
</Breadcrumb>
<div class='goal-page _gap24'>
  <h2>{$_('goal.title')}</h2>
  <hr />
  {#if goals.length === 0}
    <div>{$_('goal.noGoalSet')}</div>
  {:else}
    {#each goals as goal}
      <div class='info-and-progress'>
        <div class='each-goal-info -{goal.status}'>
          <div class='goal-header -{goal.status}'>
            <div class='language _row'>
              <Flag iso={getIsoFromId(goal.id_language)} />
              <a class='level _row' href={`/goals/${goal.id}`} use:link>
                <span>{goal.language}</span>
                <span>•</span>
                <span>{goal.level}</span>
              </a>
            </div>
            <div class='goalStatus _row'>
              <span class='statusInfo'>
                {#if goal.status === 'success'}
                  <Icon icon='CheckCircle' weight='fill' />
                  {:else if goal.status === 'active'}
                    <Icon icon='Fire' weight='fill' />
                  {:else if goal.status === 'fail'}
                    <Icon icon='XCircle' weight='fill' />
                {/if}
                {statusMessage(goal.status)}
            </span>
            </div>
          </div>
          <div class='goal-data'>
            <span class='goal-meta -{goal.status}'>
              <span class='date'><Icon icon='CalendarBlank' weight='regular' />
                <span>{goal.end_date}</span>
              </span>
              <span>•</span>
              {#if goal.status === 'active' || goal.status === 'success'}
                <span>{goal.days} {$_('goal.daysLeft')}</span>
              {:else if goal.status === 'fail'}
                <span>{$_('goal.pastDue')}</span>
              {/if}
              <span>•</span>
              <span class='percent'>{goalProgress(goal.state, goal.size)}%</span>
            </span>
            <FakeButton onClick={() => deleteGoal(toInt(goal.id))}>
              <Icon icon='Trash' weight='regular' />
              <span>{$_('delete')}</span>
            </FakeButton>
          </div>
        </div>
        <ProgressBar maxProgress={100} progress={goalProgress(goal.state, goal.size)} />
      </div>
    {/each}
  {/if}
  <hr />
  <button type='button' on:click={() => {createNewGoal = true}}>{$_('goal.addGoal')}</button>
  {#if createNewGoal}
    <div class='new-goal-wrapper'>
      <Overlay borderCloseButton onClick={() => {createNewGoal = false; sent = false}}>
        <h3 slot='heading'>{$_('goal.addGoal')}</h3>
        {#if !sent}
          <form class='_form _vertical' method='post' on:submit|preventDefault={handleSubmit}>
            <div class='select-language'>
              <div class='_fakelabel'>{$_('language')}</div>
              <LanguageSelect onChange={onLanguageChange} selectedLanguage={language} />
            </div>
            <div class='select-level'>
              <div class='_fakelabel'>{$_('level')}</div>
              <select bind:this={level} required name='level'>
                <option value=''>{$_('teach.chooseLevel')}</option>
                {#each levels as level}
                  <option value={level}>{level}</option>
                {/each}
              </select>
            </div>
            <div class='select-date'>
              <div class='_fakelabel'>{$_('goal.goalDate')}</div>
              <input
                bind:this={date}
                id='date'
                class='selectDate'
                min={today}
                required
                type='date'
                name='date'
              />
            </div>
            <input class='_maxwidth-btn' type='submit' value={$_('goal.saveGoal')} />
          </form>
        {:else}
          <EmptyState title={$_('goal.goalIsSet')} />
        {/if}
      </Overlay>
    </div>
  {/if}
</div>

<style lang='scss'>
  .info-and-progress {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > :global(.progress-container) {
      position: absolute;
      bottom: -0.5rem;
      left: 0.8rem;
      width: 98%;
    }

    > :global(.progress-container .progress-bar-cover) {
      background-color: var(--text-background);
    }
  }

  .each-goal-info {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    padding: 1.6rem 1.6rem 2rem 1.6rem;
    border-radius: 1.2rem;

    &.-active {
      background-color: var(--warning-background);
    }

    &.-success {
      background-color: var(--success-background);
    }

    &.-fail {
      background-color: var(--error-background);
    }
  }

  .goal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > .language > .level {
      font: var(--Semibold-600);
      color: var(--text-secondary-color);

      &:hover {
        text-decoration: underline;
      }
    }

    &.-active {
      background: var(--warning-background);

      > .goalStatus > .statusInfo {
        color: var(--warning-text);
      }
    }

    &.-success {
      background: var(--success-background);

      > .goalStatus > .statusInfo {
        color: var(--success-text);
      }
    }

    &.-fail {
      background: var(--error-background);

      > .goalStatus > .statusInfo {
        color: var(--error-text);
      }
    }

    > .goalStatus > .statusInfo {
      display: flex;
      gap: 0.4rem;
      padding: 0.8rem;
      font: var(--Semibold-400);
      border-radius: 0.8rem;
    }
  }

  .goal-data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font: var(--Regular-400);

    > :global(.fake-button) {
      padding: 0.4rem 0.8rem;
      color: var(--error-text);
      border-radius: 0.8rem;

      &:hover {
        color: var(--error-background);
        background-color: var(--error-text);
      }
    }
  }

  .goal-meta {
    display: flex;
    gap: 0.8rem;
    color: var(--text-primary-color);

    &.-active > .percent {
      color: var(--warning-text);
    }

    &.-success > .percent {
      color: var(--success-text);
    }

    &.-fail > .percent {
      color: var(--error-text);
    }

    > .percent {
      font: var(--Semibold-400);
    }

    > .date {
      display: flex;
      gap: 0.4rem;
    }
  }

  .new-goal-wrapper {
    > :global(.overlay-wrapper .content) {
      width: 38.4rem;
    }
  }

  .select-language, .select-level, .select-date {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    color: var(--Gray-Dark);
  }

  @media(max-width: 768px) {
    .goal-page {
      padding: 0;
      background: transparent;
      border: none;

      > h2, > hr {
        display: none;
      }
    }

    .new-goal-wrapper > :global(.overlay-wrapper .content) {
      width: 35rem;
    }

    .goal-header {
      > .goalStatus > .statusInfo {
        font: var(--Semibold-300);
      }

      > .language > .level {
        font: var(--Semibold-400);
      }
    }

    .goal-data {
      flex-direction: column;
      gap: 2.4rem;
      align-items: start;
      font: var(--Regular-300);
    }

    .info-and-progress > :global(.progress-container) {
      width: 96%;
    }
  }
</style>
